// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/firebase.png";
import post2 from "assets/images/frontend-tab.png";
import post3 from "assets/images/backend-tab.png";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Frontend Developed Projects
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Firebase CRUD Bucketlist"
              description="A bucketlist application that allows someone to login, and then create a vehicle and destination bucketlist that then stores to their profile."
              action={{
                type: "internal",
                route: "https://basic-crud-app-b164e.web.app",
                color: "warning",
                label: "view app",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Coming soon..."
              description="Watch this space for further relevant projects."
              action={{
                type: "internal",
                route: "https://davidkoen.tech/contact-me/",
                color: "warning",
                label: "contact me",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Coming soon..."
              description="Watch this space for further relevant projects."
              action={{
                type: "internal",
                route: "https://davidkoen.tech/contact-me/",
                color: "warning",
                label: "contact me",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post3}
              title="Backend Environment"
              description="I am also able to work with the technologies relating to backend development."
              action={{
                type: "internal",
                route: "https://davidkoen.tech/portfolio/backend-environment/",
                label: "view space",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
