// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

const routes = [
  {
    name: "pages",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "main environment",
        collapse: [
          {
            name: "portfolio",
            route: "https://davidkoen.tech/",
          },
          {
            name: "about me",
            route: "https://davidkoen.tech/about-me/",
          },
          {
            name: "contact me",
            route: "https://davidkoen.tech/contact-me/",
          },
        ],
      },
    ],
  },
  {
    name: "github",
    icon: <GitHubIcon />,
    href: "https://github.com/TheDavidKoen",
  },
];

export default routes;
